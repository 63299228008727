import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Typography from "../ui/Typography"
import Button from "../ui/Button"

const CollectionDetails = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  flex-direction: column-reverse;
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    flex-direction: row;
    padding-right: 200px;
  }
`

const GenderContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  padding-left: 0;
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    min-width: 200px;
    padding: 0;
  }
`
const Content = styled.div`
  flex-grow: 1;
`

const Body = styled.div`
  ${Typography.Large}
  display: inline;
  > * {
    display: inline;
  }
`
const Title = styled.div`
  ${Typography.Large}
  display: inline;
`

const CollectionDetailsContainer = ({
  hasGender,
  changeGender,
  gender,
  name,
  description,
  alternate,
}) => {
  const { t } = useTranslation()
  return (
    <CollectionDetails alternate={alternate}>
      <GenderContainer>
        {hasGender && (
          <Button secondary onClick={changeGender}>
            {t(gender === "male" ? "view_female" : "view_male")}
          </Button>
        )}
      </GenderContainer>
      <Content>
        <Title>
          {name} —{" "}
          <Body
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        </Title>
      </Content>
    </CollectionDetails>
  )
}

CollectionDetailsContainer.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
}

export default CollectionDetailsContainer
