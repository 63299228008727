import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { trackProductListViewed } from "../../services/analytics"
import ContentModules from "../Page/ContentModules/ContentModules"
import { BundleGrid, ProductGrid } from "../Page/ContentModules/Grid"

const GridContainer = ({
  products,
  productGrid,
  collectionPath,
  collection,
  showProductGrid,
  name,
  imageSizes,
}) => {
  // Track impression of the product list.
  useEffect(() => {
    trackProductListViewed(name, products)
  })

  return showProductGrid ? (
    <ContentModules
      allModules={productGrid}
      collection={collection}
      collectionPath={collectionPath}
      imageSizes={imageSizes}
    />
  ) : (
    <ProductGrid
      collection={collection}
      products={products}
      collectionPath={collectionPath}
      imageSizes={imageSizes}
    />
  )
}

export const GridBundleContainer = ({ bundle, catSlug }) => {
  return <BundleGrid bundle={bundle} catSlug={catSlug} />
}

GridContainer.propTypes = {
  products: PropTypes.array.isRequired,
  collectionPath: PropTypes.string.isRequired,
}

export default GridContainer
