import React, { useContext } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import Layout from "../components/layouts"
import CollectionDetails from "../components/ProductGrid/CollectionDetails"
import GridContainer from "../components/ProductGrid/GridContainer"
import SEO from "../components/seo"
import { hasGender } from "../utils/functions/product-util"
import { PersonalizationContext } from "../context/personalization-context"
import {
  handleUrlPrefix,
  simpleWordTranslate,
} from "../utils/translations-util"
import { useTranslationContext } from "../context/TranslationContext"

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.almostWhite};
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 50px;
`

const ProductGridTemplate = ({ data, pageContext }) => {
  const {
    contentfulCollection: collection,
    contentfulCollection: {
      metaImage,
      name,
      description,
      imageSizes,
      showProductGrid,
      productPageType,
      seoTitle,
      seoDescription,
    },
  } = data
  const { translationContext } = useTranslationContext()
  let columns = 4
  if (collection.imageSizes === "Medium") {
    columns = 3
  } else if (collection.imageSizes === "Large") {
    columns = 2
  }

  const { gender, changeGender } = useContext(PersonalizationContext)

  let path = pageContext.collectionPath

  if (productPageType === "Standalone") {
    const lang = pageContext.locale
    path = handleUrlPrefix(lang, `${simpleWordTranslate(lang, "products")}`)
  }

  return (
    <Layout pullUp almostWhite>
      <SEO
        metaImage={metaImage && metaImage.file.url}
        canonicals={translationContext.canonicals}
        title={seoTitle || name}
        description={seoDescription?.seoDescription || description.description}
      />
      <Container>
        <CollectionDetails
          hasGender={hasGender(
            showProductGrid ? collection.productGrid : collection.products
          )}
          gender={gender}
          changeGender={changeGender}
          name={name}
          description={description}
        />
        <GridContainer
          hasGender={hasGender(
            showProductGrid ? collection.productGrid : collection.products
          )}
          gender={gender}
          name={name}
          columns={columns}
          description={description}
          collection={collection}
          collectionPath={path}
          products={collection.products}
          productGrid={collection.productGrid}
          showProductGrid={showProductGrid}
          imageSizes={imageSizes}
        />
      </Container>
    </Layout>
  )
}

export default ProductGridTemplate

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    contentfulCollection(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      name
      slug
      imageSizes
      showProductGrid
      productPageType
      metaImage {
        file {
          url
        }
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      ...CollectionProducts

      productGrid {
        ...CollectionProduct
        ...BeddingBundle
        ...Hero
        ...TileSection
      }
    }
  }
`
